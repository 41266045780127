import React, { ComponentProps, useState } from 'react'

import {
  AlertIcon,
  Filter,
  FilterDivider,
  FilterItem,
  FormText,
  PhoneIcon,
  Spacer
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

type Value = ComponentProps<typeof Filter>['value']

const FilterMultipleExample = () => {
  const [value, setValue] = useState<Value>()

  return (
    <>
      <Filter
        ariaLabel="Tapahtumavirran suodatus"
        multiple
        onChange={e => setValue(e)}
        value={value}
      >
        <FilterItem
          badgeIcon={<AlertIcon />}
          reset
        >
          Kaikki
        </FilterItem>
        <FilterDivider />
        <FilterItem value="tyonhaku">Työnhaku</FilterItem>
        <FilterItem
          value="palvelutarve"
          badgeIcon={<AlertIcon />}
        >
          Palvelutarve
        </FilterItem>
        <FilterItem
          disabled
          value="suunnitelmat-ja-tehtavat"
        >
          Suunnitelmat ja tehtävät
        </FilterItem>
        <FilterItem
          value="tyottomyysetuus"
          badgeIcon={<AlertIcon />}
        >
          Työttömyysetuus
        </FilterItem>
        <FilterItem value="tyo-ja-koulutustarjoukset">
          Työ- ja koulutustarjoukset
        </FilterItem>
        <FilterItem
          iconLeft={<PhoneIcon />}
          value="yhteydenotot"
        >
          Yhteydenotot
        </FilterItem>
      </Filter>
      <Spacer marginTop="md">
        <FormText>
          Arvo: <Code>{JSON.stringify(value)}</Code>
        </FormText>
      </Spacer>
    </>
  )
}

export { FilterMultipleExample }
