import React, { ComponentProps, useState } from 'react'

import {
  AlertIcon,
  Filter,
  FilterItem,
  FormText,
  PhoneIcon,
  Spacer
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'

const FilterSizeExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof Filter>['value']>('tyonhaku')

  return (
    <>
      <Filter
        error="Error"
        help="Help"
        label="Label"
        onChange={e => setValue(e)}
        required
        subLabel="Sub Label"
        size="md"
        value={value}
      >
        <FilterItem value="tyonhaku">Työnhaku</FilterItem>
        <FilterItem
          value="palvelutarve"
          badgeIcon={<AlertIcon />}
        >
          Palvelutarve
        </FilterItem>
        <FilterItem
          disabled
          value="suunnitelmat-ja-tehtavat"
        >
          Suunnitelmat ja tehtävät
        </FilterItem>
        <FilterItem
          value="tyottomyysetuus"
          badgeIcon={<AlertIcon />}
        >
          Työttömyysetuus
        </FilterItem>
        <FilterItem value="tyo-ja-koulutustarjoukset">
          Työ- ja koulutustarjoukset
        </FilterItem>
        <FilterItem
          iconLeft={<PhoneIcon />}
          value="yhteydenotot"
        >
          Yhteydenotot
        </FilterItem>
      </Filter>
      <Spacer marginTop="md">
        <FormText>
          Arvo: <Code>{JSON.stringify(value)}</Code>
        </FormText>
      </Spacer>
    </>
  )
}

export { FilterSizeExample }
