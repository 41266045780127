import React from 'react'

import { Filter, FilterItem } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { FilterExample } from '../../examples/react/FilterExample'
import { FilterMultipleExample } from '../../examples/react/FilterMultipleExample'
import { FilterSizeExample } from '../../examples/react/FilterSizeExample'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Filter"
    components={[{ component: Filter }, { component: FilterItem }]}
  >
    <Section>
      <Playground
        example={FilterExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Monivalinta">
      <Playground
        example={FilterMultipleExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
    <Section title="Koko">
      <Playground
        example={FilterSizeExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
  </Content>
)

export default Page
